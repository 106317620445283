<modal :title="modalDetails?.title    || 'Warning'" :handler="true" :cssClass="'c-modal-small-2 content-px-0'"
    :footer="false" @modalHandler="onCancel">
    <template v-slot:content>
        <div class="px-16 pb-4">
            <p class="font-16 m-0">
                {{modalDetails?.message || ' Are you sure you want to delete?'}}
            </p>
        </div>
        <dialog-actions-bar>
                    <kbutton @click="onCancel" :class="'w-100'">{{modalDetails?.cancelButton || 'No'}}</kbutton>
                    <kbutton
                        :theme-color="'primary'"
                        @click="onConfirm" :class="'w-100 h-38'">
                        {{modalDetails?.confirmButton || 'Yes'}}</kbutton>
        </dialog-actions-bar>
    </template>
</modal>