<template src="./AllCampaign.html"></template>
<style lang="scss">
@import "./AllCampaign.scss";
</style>

<script>
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImage,
  CardActions,
} from "@progress/kendo-vue-layout";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Popup } from "@progress/kendo-vue-popup";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar";
import AppLoader from "@/components/common/AppLoader/AppLoader";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import AppDateRangePicker from "@/components/common/AppDateRangePicker/AppDateRangePicker.vue";
import {
  getEmailCampaignList,
  cloneEmailCampaign,
  deleteEmailCampaign,
  updateEmailCampaignStatus,
  updateEmailCampaign,
  updateArchiveCampaign,
} from "@/services/clubDetails/emailCampaign.service";
import WarningModal from "../warningModal/WarningModal.vue";
import RescheduleCampaignModal from "../rescheduleCampaignModal/RescheduleCampaignModal.vue";
import moment from "moment";
import { Grid } from "@progress/kendo-vue-grid";
export default {
  name: "AllCampaign",
  components: {
    kbutton: Button,
    "k-input": Input,
    dropdownlist: DropDownList,
    card: Card,
    grid: Grid,
    cardHeader: CardHeader,
    cardBody: CardBody,
    cardTitle: CardTitle,
    cardImage: CardImage,
    cardActions: CardActions,
    Popup: Popup,
    AppPopUp,
    Breadcrumb,
    AppSnackBar,
    AppLoader,
    WarningModal,
    RescheduleCampaignModal,
    AppDateRangePicker,
  },
  data() {
    return {
      moduleCode: "emailCampaign",
      itemCode: "allCampaign",
      selectedCampaign: "",
      isLoading: false,
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      debounce: null,
      clubId: this.$route.params.id,
      searchQuery: "",
      searchText: "",
      cardIdDlt: "",
      isSearching: false,
      campaignStatusTemplates: [
        { text: "All", id: 0 },
        { text: "Live", id: 2 },
        { text: "Draft", id: 1 },
        { text: "Scheduled", id: 3 },
        { text: "Paused", id: 4 },
        { text: "Completed", id: 5 },
        { text: "Cancelled", id: 6 },
        // { text: "Scheduled", id: 7 },
      ],
      campaignType: [
        { text: "All", id: 0 },
        { text: "Campaign", id: 1 },
        { text: "Flow Automation", id: 2 },
      ],
      filterByDateType: [
        { text: "All Time", id: 0 },
        { text: "7 days", id: 1 },
        { text: "30 days", id: 2 },
        { text: "90 days", id: 3 },
        { text: "Custom", id: 4 },
      ],
      campaignTypeValue: { text: "Campaign", id: 1 },
      filterByDateValue: { text: "All Time", id: 0 },
      campaignValue: { text: "All", id: 0 },
      show: true,

      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100],
        previousNext: true,
      },

      campaignsList: [],
      campaignStatus: {
        1: "Draft",
        2: "Live",
        3: "Scheduled",
        4: "Paused",
        5: "Completed",
        6: "Cancelled",
        7: "Scheduled",
      },

      campaignStatusColor: {
        1: "draft",
        2: "live",
        3: "scheduled",
        4: "paused",
        5: "completed",
        6: "",
        7: "scheduled",
      },

      deleteCampaignModalVisibility: false,
      payloadForDeleteCampaign: {},
      deleteCampaignModalDetails: {
        title: "Delete Campaign",
        message:
          "You are about to delete a draft campaign. You will loose all saved settings and information. Are you sure you want to delete this campaign?",
        confirmButton: "Delete Campaign",
        cancelButton: "Cancel",
      },

      resumeCampaignWarningModalVisibility: false,
      payloadForResumeCampaign: {},
      resumeCampaignWarningModalDetails: {
        title: "Resume Campaign",
        message:
          "You are about to resume your campaign that was paused. Are you sure you want to resume your campaign?",
        confirmButton: "Resume Campaign",
        cancelButton: "Cancel",
      },

      pauseCamaignWarningModalVisibility: false,
      payloadForPauseCampaign: {},
      pauseCampaignWarningModalDetails: {
        title: "Pause Campaign",
        message:
          "You are about to pause your campaign that is running. No recipients will enter the campaign if the campaign is paused and no further analytics will be generated. Are you sure you want to pause the campaign?",
        confirmButton: "Pause Campaign",
        cancelButton: "Cancel",
      },

      RescheduleCampaignModalVisibility: false,
      campaignDetailsForReschedule: {},
      sortBy: "",
      sortDir: "",
      isArchived: false,
      sort: [],
      payload: {},
      allCmpaignColumns: [
        {
          field: "campaignName",
          cell: "nameCell",
          title: "Campaign",
          sortable: false,
        },
        {
          field: "typesOfDelivery",
          cell: "typesOfDeliveryCell",
          title: "Type",
          sortable: false,
        },
        {
          field: "status",
          cell: "statusCell",
          title: "Status",
          sortable: false,
        },
        {
          field: "createdAt",
          cell: "createdAtCell",
          title: "Created On",
          sortable: true,
        },
        {
          field: "LastUpdateAt",
          cell: "LastUpdateAtCell",
          title: "Last Updated On",
          sortable: true,
        },
        {
          field: "openRate",
          cell: "openRateCell",
          title: "Open Rate",
          sortable: false,
        },
        {
          field: "bounceRate",
          cell: "bounceRateCell",
          title: "Bounce Rate",
          sortable: false,
        },
        {
          field: "conversionRate",
          cell: "conversionRateCell",
          title: "Conversion",
          sortable: false,
        },
        {
          field: "",
          cell: "actionCell",
          title: "Action",
          sortable: false,
        },
      ],
    };
  },
  created() {
    const clubInfo = JSON.parse(window.localStorage.getItem("clubInfo"));
    if (clubInfo?.roleId === 2) {
      this.ifUserIsReportViewer = true;
    } else {
      this.ifUserIsReportViewer = false;
    }
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }

    this.isNotify = false;
    this.$store.dispatch("getTimeZonesList");
    this.reloadCampaigns();
  },
  computed: {
    clubName() {
      return this.$store.state.club.club.name;
    },
    isMenuLoading() {
      return this.$store.state.common.isMenuLoading;
    },
    total() {
      return this.campaignsList ? this.campaignsList?.size : 0;
    },
    take() {
      return this.campaignsList ? this.campaignsList?.limit : 0;
    },
    skip() {
      this.selectedItems = [];
      return this.campaignsList ? this.campaignsList?.offset : 0;
    },
  },
  methods: {
    resetFilters() {
      this.filterByDateValue = { text: "All Time", id: 0 };
      this.campaignValue = { text: "All", id: 0 };
      this.searchQuery = "";
    },
    toggleCampaign(isArchived) {
      this.resetFilters();
      this.isArchived = isArchived;

      this.payload = {
        ...this.filterPayload(this.payload),
        clubId: this.clubId,
        campaignGroup: this.campaignTypeValue?.id,
        filterByDateType: this.filterByDateValue?.id,
        searchQuery: this.searchQuery,
        ...(this.campaignValue?.id && {
          CampaignStatus: this.campaignValue?.id,
        }),
        limit: this.take,
        offset: 0,
        isArchived: this.isArchived,
      };
      this.getEmailCampaigns({
        ...this.payload,
      });
    },
    handleDateSelected({ start, end }) {
      if (start == null && end == null) {
        this.filterByDateValue = { text: "All Time", id: 0 };
      }

      this.payload = {
        ...this.filterPayload(this.payload),
        clubId: this.clubId,
        campaignGroup: this.campaignTypeValue?.id,
        filterByDateType: this.filterByDateValue?.id,
        searchQuery: this.searchQuery,
        ...(this.campaignValue?.id && {
          CampaignStatus: this.campaignValue?.id,
        }),
        limit: this.take,
        offset: 0,
        isArchived: this.isArchived,
      };
      this.payload.startDate = start != null ? moment(start).format("DD-MMM-YYYY") : null;
      this.payload.endDate = end != null ? moment(end).format("DD-MMM-YYYY") : null;

      this.getEmailCampaigns({
        ...this.payload,
      });
    },

    sortChangeHandler: function (e) {
      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }

      this.payload = {
        ...this.filterPayload(this.payload),
        clubId: this.clubId,
        campaignGroup: this.campaignTypeValue?.id,
        filterByDateType: this.filterByDateValue?.id,
        searchQuery: this.searchQuery,
        ...(this.campaignValue?.id && {
          CampaignStatus: this.campaignValue?.id,
        }),
        isArchived: this.isArchived,
        limit: this.take,
        offset: this.skip,
      };

      if (this.sortBy && this.sortDir) {
        this.payload.sort = this.sortBy;
        this.payload.order = this.sortDir;
      }

      this.getEmailCampaigns({
        ...this.payload,
      });
    },

    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;
      this.payload = {
        ...this.filterPayload(this.payload),
        clubId: this.clubId,
        campaignGroup: this.campaignTypeValue?.id,
        filterByDateType: this.filterByDateValue?.id,
        searchQuery: this.searchQuery,
        ...(this.campaignValue?.id && {
          CampaignStatus: this.campaignValue?.id,
        }),
        limit: event.data.take,
        offset: event.data.skip,
        isArchived: this.isArchived,
      };

      if (this.sortBy && this.sortDir) {
        this.payload.sort = this.sortBy;
        this.payload.order = this.sortDir;
      }
      this.getEmailCampaigns({
        ...this.payload,
      });
    },

    generateCampaignActions(id, propsItem) {
      let actions = {
        1: [
          ...(!this.hasCreateAccess()
            ? []
            : [
              {
                text: "Clone",
                dropId: 1,
              },
            ]),

          ...(!this.hasDeleteAccess()
            ? []
            : [
              {
                text: "Delete",
                dropId: 3,
              },
            ]),
        ],
        2: [
          ...(!this.hasCreateAccess()
            ? []
            : [
              {
                text: "Clone",
                dropId: 1,
              },
            ]),
          ...(!this.hasGetAccess()
            ? []
            : [
              {
                text: "View Analytics",
                dropId: 9,
              },
            ]),
        ],
        3: [
          ...(!this.hasEditAccess()
            ? []
            : [
              {
                text: "Clone",
                dropId: 1,
              },
              {
                text: "Pause",
                dropId: 4,
              },
            ]),
        ],
        4: [
          ...(!this.hasEditAccess()
            ? []
            : [
              {
                text: "Resume",
                dropId: 5,
              },
            ]),
          ...(!this.hasCreateAccess()
            ? []
            : [
              {
                text: "Clone",
                dropId: 1,
              },
            ]),
          ...((this.hasGetAccess() && propsItem?.previousCampaignStatus != 3)
            ? [{
              text: "View Analytics",
              dropId: 9,
            },]
            : [
            ]),
        ],
        5: [
          ...(!this.hasCreateAccess()
            ? []
            : [
              {
                text: "Clone",
                dropId: 1,
              },
            ]),
          ...(!this.hasGetAccess()
            ? []
            : [
              {
                text: "View Analytics",
                dropId: 9,
              },
            ]),
        ],
        6: [
          ...(!this.hasCreateAccess()
            ? []
            : [
              {
                text: "Clone",
                dropId: 1,
              },
            ]),
          // {
          //   text: "Preview",
          //   dropId: 2,
          // },
        ],
        7: [
          ...(!this.hasCreateAccess()
            ? []
            : [
              {
                text: "Clone",
                dropId: 1,
              },
            ]),
        ],
      };

      if (propsItem.isArchived) {
        actions[id].push({
          text: "Unarchive",
          dropId: 8,
        });
      } else {
        actions[id].push({
          text: "Archive",
          dropId: 7,
        });
      }
      return actions[id];
    },

    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },

    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    emailOptionsGenerator(total, option) {
      if (total || option) {
        return (100 / total) * option;
      } else {
        return 0;
      }
    },
    getEmailCampaigns(payload) {
      this.isLoading = true;
      getEmailCampaignList(payload)
        .then((res) => {
          this.campaignsList = res?.data?.data;
          this.isLoading = false;

          if (payload.searchQuery) {
            this.searchText = payload.searchQuery;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    getDate(date) {
      if (date != null) {
        if (!date.includes('Z')) {
          date += 'Z';
        }
        return moment(date).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },

    getTime(date) {
      if (date != null) {
        if (!date.includes('Z')) {
          date += 'Z';
        }
        return moment(date).format("hh:mmA");
      }
    },

    doNavigateToViewCampaign(campaign) {
      if (campaign?.campaignStatus == 1) {
        this.$router.push({
          name: "CampaignEdit",
          params: {
            ...this.$route.params,
            campaignId: campaign?.campaignId,
            campaignName: campaign?.campaignName,
          },
          query: {
            type: 2,
          },
        });
      } else {
        this.$router.push({
          name: "CampaignDetails",
          params: {
            ...this.$route.params,
            campaignId: campaign?.campaignId,
            campaignName: campaign?.campaignName,
          },
        });
      }
    },

    filterPayload(payload) {
      const { CampaignStatus, ...filteredPayload } = payload;
      return filteredPayload;
    },

    reloadCampaigns() {
      this.payload = {
        ...this.filterPayload(this.payload),
        clubId: this.clubId,
        offset: this.skip,
        limit: this.take,
        searchQuery: this.searchQuery,
        ...(this.campaignValue?.id && {
          CampaignStatus: this.campaignValue?.id,
        }),
        isArchived: this.isArchived,
        campaignGroup: this.campaignTypeValue?.id,
        filterByDateType: this.filterByDateValue?.id,
      };
      this.getEmailCampaigns({
        ...this.payload,
      });
    },

    handleCampaignStatusChange(e) {
      this.campaignValue = e.value;
      this.reloadCampaigns();
    },

    handleCampaignTypeStatusChange(e) {
      this.campaignTypeValue = e.value;
      this.reloadCampaigns();
    },

    handleFilterByDateChange(e) {
      this.filterByDateValue = e.value;
      if (this.filterByDateValue.id === 4) return;
      this.reloadCampaigns();
    },

    navigateToCreatePage() {
      this.$router.push({
        name: "CreateCampaignStepOne",
        query: { club_name: this.clubName },
      });
    },

    cloneCampaignHandler(payload) {
      this.isLoading = true;
      payload = {
        ...payload,
        clubId: this.clubId,
      };
      cloneEmailCampaign(payload)
        .then((res) => {
          this.isLoading = false;
          this.showNotification("Campaign cloned successfully.", "success");
          this.reloadCampaigns();
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },

    cardNameFormat(name) {
      return name.length <= 45 ? name : name.substring(0, 45) + "..";
    },
    showNotification(message, type, time = 5000) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        time
      );
    },

    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },

    onSearch(e) {
      this.searchQuery = e.value;
      this.isSearching = true;
      if (!this.searchQuery) {
        setTimeout(() => {
          this.isSearching = false;
        }, 1000);
      }

      this.reloadCampaigns();
    },
    statusChangeSuccessMessageGenerator(status, prevStatus) {
      if (status == 4) {
        return "Campaign has been paused.";
      } else if (status == 2) {
        return "Campaign has been resumed.";
      } else if (status == 5) {
        return "Campaign has been ended.";
      } else if (status == 6) {
        return "Campaign has been cancelled.";
      }
    },
    deleteCampaignHandler(payload) {
      this.isLoading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      deleteEmailCampaign(payload)
        .then((res) => {
          this.isLoading = false;
          this.showNotification("Campaign deleted successfully.", "success");
          this.reloadCampaigns();
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },
    navigateToViewAnalytics(campaign) {
      if (campaign?.campaignStatus == 1) {
        this.$router.push({
          name: "CampaignAnalytics",
          params: {
            ...this.$route.params,
            campaignId: campaign?.campaignId,
            campaignName: campaign?.campaignName,
          },
          query: {
            type: 2,
            campaignName: campaign?.campaignName,
          },
        });
      } else {
        this.$router.push({
          name: "CampaignAnalytics",
          params: {
            campaignId: campaign?.campaignId,
          },
          query: {
            type: 2,
            campaignName: campaign?.campaignName,
          },
        });
      }
    },

    campaignStatusChangeHandler(payload) {
      this.isLoading = true;
      let message = this.statusChangeSuccessMessageGenerator(
        payload?.campaignStatus,
        this.selectedCampaign?.previousCampaignStatus
      );
      payload = {
        ...payload,
        clubId: this.clubId,
      };
      updateEmailCampaignStatus(payload)
        .then((res) => {
          this.isLoading = false;
          this.showNotification(message, "success");
          this.reloadCampaigns();
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },

    onCancelDeleteCampaignModal() {
      this.deleteCampaignModalVisibility = false;
    },

    onConfirmDeleteCampaignModal() {
      this.deleteCampaignModalVisibility = false;
      this.deleteCampaignHandler(this.payloadForDeleteCampaign);
    },

    onCancelResumeCampaign() {
      this.resumeCampaignWarningModalVisibility = false;
    },

    onConfirmResumeCampaign() {
      this.resumeCampaignWarningModalVisibility = false;
      this.campaignStatusChangeHandler(this.payloadForResumeCampaign);
    },

    onCancelPauseCampaign() {
      this.pauseCamaignWarningModalVisibility = false;
    },

    onConfirmPauseCampaign() {
      this.pauseCamaignWarningModalVisibility = false;

      this.campaignStatusChangeHandler(this.payloadForPauseCampaign);
    },

    onCancelRescheduleCampaign() {
      this.RescheduleCampaignModalVisibility = false;
    },

    onConfirmRescheduleCampaign(payload) {
      this.RescheduleCampaignModalVisibility = false;
      this.campaignUpdateHandler(payload);
    },

    getDropId(e, item) {
      this.selectedCampaign = item;
      if (e == 1) {
        const payload = {
          campaignId: item.campaignId,
          campaignStatus: 1,
        };
        this.cloneCampaignHandler(payload);
      }
      if (e == 2) {
      }
      if (e == 3) {
        this.payloadForDeleteCampaign = {
          campaignId: item.campaignId,
        };
        this.deleteCampaignModalVisibility = true;
      }

      if (e == 4) {
        this.payloadForPauseCampaign = {
          campaignId: item.campaignId,
          campaignStatus: 4,
        };
        if (item?.campaignStatus == 3) {
          this.pauseCamaignWarningModalVisibility = true;
          this.pauseCampaignWarningModalDetails.message =
            "You are about to pause your campaign that is scheduled. You may have to reschedule if you want to resume this campaign. Are you sure you want to pause this scheduled campaign?";
        } else {
          this.pauseCamaignWarningModalVisibility = true;
        }
      }

      if (
        e == 5 &&
        item?.campaignStatus == 4 &&
        item?.previousCampaignStatus != 3
      ) {
        this.payloadForResumeCampaign = {
          campaignId: item.campaignId,
          campaignStatus: 2,
        };
        this.resumeCampaignWarningModalVisibility = true;
      }

      if (
        e == 5 &&
        item?.campaignStatus == 4 &&
        item?.previousCampaignStatus == 3
      ) {
        this.RescheduleCampaignModalVisibility = true;
      }
      if (e == 7) {
        let payload = {
          campaignId: item.campaignId,
          clubId: this.$route.params.id,
          isArchived: true,
        };
        this.updateArchiveCampaignHandler(payload);
      }
      if (e == 8) {
        let payload = {
          campaignId: item.campaignId,
          clubId: this.$route.params.id,
          isArchived: false,
        };
        this.updateArchiveCampaignHandler(payload);
      }
      if (e == 9) {
        this.navigateToViewAnalytics(item);
      }
    },
    updateArchiveCampaignHandler(payload) {
      this.isLoading = true;
      updateArchiveCampaign(payload)
        .then((res) => {
          this.isLoading = false;
          if (payload?.isArchived)
            this.showNotification("Email campaign archived successfully.", "success");
          else
            this.showNotification("Email campaign unarchived successfully.", "success");
          this.reloadCampaigns();
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },
    campaignUpdateHandler(payload) {
      this.isLoading = true;
      updateEmailCampaign(payload)
        .then((res) => {
          this.isLoading = false;
          this.showNotification(
            "Campaign has been rescheduled to selected date and time.",
            "success"
          );
          this.reloadCampaigns();
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },
    percentageCalc(value, total) {
      if (value == 0 && total == 0) {
        return 0;
      } else {
        if (isFinite(value / total)) {
          return parseFloat(((value / total) * 100).toFixed(2));
        } else {
          return 0;
        }
      }
    },
  },
};
</script>
<style scoped></style>
