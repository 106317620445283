<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>

<modal title="Reschedule Your Campaign" :handler="true" :cssClass="'c-modal-md content-px-0'" :footer="false"
    @modalHandler="this.onCancel">
    <template v-slot:content>
        <div>
            <div class="px-16 pb-24">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="">Time Zone</label>
                        <dropdownlist :data-items="this.timeZonesList" :text-field="'name'" :data-item-key="'id'"
                            :filterable="true" @filterchange="filterChange" :value="this.timeZoneValue"
                            @change="this.handleTimeZoneChangeValue">
                        </dropdownlist>
                        <span v-if="timeZoneValue?.id===999"
                            class="font-14 text-color-2 mt-1 d-flex align-items-center gap-1">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                                    <path id="Path_1" data-name="Path 1"
                                        d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20ZM10,5a.935.935,0,0,1,.938.938v4.375a.938.938,0,0,1-1.875,0V5.938A.935.935,0,0,1,10,5ZM8.75,13.75A1.25,1.25,0,1,1,10,15,1.25,1.25,0,0,1,8.75,13.75Z"
                                        fill="#ffd43b" />
                                </svg>
                            </span>
                            Choose a time 24 hours ahead of the current time.
                        </span>
                    </div>

                    <div class="col-md-6">
                        <label for="">Date</label>
                        <datePicker :format="'dd-MMM-yyyy'" :default-value="scheduleDate" @change="updateScaduleDate"
                            :min="new Date()" :value="scheduleDate">
                        </datePicker>
                    </div>

                    <div class="col-md-6">
                        <label for="">Time</label>
                        <timePicker :class="''" :value="scheduleTime" @change="handleResumeWorkflowStartTime" />
                    </div>
                </div>
            </div>
        </div>
        <dialog-actions-bar>
            <div class="d-flex justify-content-end">
                <div class="me-1">
                    <kbutton @click="this.onCancel" :class="'w-100'">Cancel</kbutton>
                </div>
                <div class="ms-1">
                    <kbutton :theme-color="'primary'" @click="this.onResumeHandler" :class="'w-100'">Resume</kbutton>
                </div>
            </div>
        </dialog-actions-bar>
    </template>
</modal>