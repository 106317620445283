<template>
  <DatePicker
    @change="handleChange"
    placeholder="Please select a date range"
    :disabled-date="disableDatesHandler"
    v-model:value="dateRangeModel"
    range
  >
  </DatePicker>
</template>

<script>
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";

export default {
  components: {
    DatePicker,
  },
  props: {
    dateRangeProps: {
      type: Array,
      default: () => [null, null],
    },
    disableFutureDates: {
      type: Boolean,
      default: false,
    },
    prevDateDisabledBefore: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateRangeModel: null,
      startDate: null,
      endDate: null,
    };
  },
  mounted() {
    this.dateRangeModel = this.convertToDateObjects(this.dateRangeProps);
  },
  watch: {
    dateRangeProps: {
      handler(newVal) {
        this.dateRangeModel = this.convertToDateObjects(newVal);
      },
      deep: true,
    },
  },
  methods: {
    disableDatesHandler(date) {
      let prevDateDisabledBefore = null;
      if (this.prevDateDisabledBefore) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        prevDateDisabledBefore = new Date(today);
        prevDateDisabledBefore.setDate(
          today.getDate() - this.prevDateDisabledBefore
        );
      }

      if (!this.disableFutureDates) {
        return false;
      }
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return (
        date > today ||
        (prevDateDisabledBefore && date < prevDateDisabledBefore)
      );
    },
    convertToDateObjects(dateArray) {
      return dateArray.map((dateString) =>
        dateString ? new Date(dateString) : null
      );
    },
    handleChange(value) {
      const [start, end] = value;

      if (start && end && start > end) {
        this.startDate = null;
        this.endDate = null;
        this.$emit("error", "Start date cannot be after end date");
        return;
      }

      this.startDate = start;
      this.endDate = end;

      this.$emit("dateSelected", { start: this.startDate, end: this.endDate });
    },
  },
};
</script>
