<template src="./WarningModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    payload: {
      type: Object,
      required: false,
    },
    modalDetails: {
      //   type: Object,
      required: false,
    },
    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  data: function () {
    return {
      clubId: this.$route.params.id,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
