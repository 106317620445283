<template src="./RescheduleCampaignModal.html"></template>
<style lang="scss">
@import "./RescheduleCampaignModal.scss";
</style>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import modal from "@/components/common/AppModal/AppModal.vue";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import { changeTimeZone } from "@/utils/helper";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import moment from "moment";
import { TimePicker } from "@progress/kendo-vue-dateinputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { filterBy } from "@progress/kendo-data-query";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    modal,
    radiogroup: RadioGroup,
    dropdownlist: DropDownList,
    datetimepicker: DateTimePicker,
    AppSnackBar,
    datePicker: DatePicker,
    timePicker: TimePicker,
  },
  props: {
    prePayload: {
      type: Object,
      required: true,
    },
    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  data: function () {
    return {
      timeZoneLocal: Intl.DateTimeFormat().resolvedOptions().timeZone,
      clubId: this.$route.params.id,
      dateTimeValue: new Date(),
      timeZoneValue: {},
      timeZoneClubValue: {},
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      scheduleDate: new Date(),
      scheduleTime: new Date(),
    };
  },
  computed: {
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
    club() {
      return this.$store.state.club.club;
    },
  },

  mounted() {
    this.timeZoneClubValue = this.timeZoneInit();
    this.dataReloaderFunction();
  },

  methods: {
    filterChange(event) {
      this.searchData = "";
      let data = this.$store.state.commonEmailCampaign.timeZoneTempList;
      this.searchData = filterBy(data, event.filter);
      this.$store.commit("SET_TIME_ZONE_LIST", this.searchData);
    },

    updateScaduleDate(event) {
      this.scheduleDate = event.target.value;
    },

    handleResumeWorkflowStartTime(e) {
      this.scheduleTime = e.value;
    },

    combineDateTime(dateString, timeString) {
      if (!dateString || !timeString) {
        return null;
      }

      const date = new Date(dateString);
      const time = new Date(timeString);

      if (isNaN(date.getTime()) || isNaN(time.getTime())) {
        return null;
      }

      const combinedDate = new Date(date);
      combinedDate.setHours(time.getHours());
      combinedDate.setMinutes(time.getMinutes());
      combinedDate.setSeconds(0);
      combinedDate.setMilliseconds(0);

      return combinedDate;
    },
    timeZoneInit() {
      let timeZone = this.club.timeZoneId;
      if (timeZone != 999) {
        return this.timeZonesList.find((item) => item.id === timeZone);
      } else {
        return this.timeZonesList.find(
          (item) =>
            item.name === Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      }
    },
    dataReloaderFunction() {
      if (this.prePayload.timeZoneId) {
        this.timeZoneValue = this.timeZonesList.find((item) => {
          return item.id === this.prePayload.timeZoneId;
        });
      } else {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        this.timeZoneValue = this.timeZonesList.find((item) => {
          return item.name === timeZone;
        });
      }

      if (this.prePayload.dateOfSchedule) {
        this.dateTimeValue = new Date(this.prePayload.dateOfSchedule);
        this.scheduleDate = new Date(this.prePayload.dateOfSchedule);
        this.scheduleTime = new Date(this.prePayload.dateOfSchedule);
      }
    },

    updateDateAndTime(event) {
      this.dateTimeValue = event.target.value;
    },

    handleTimeZoneChangeValue(e) {
      this.timeZoneValue = e.value;
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },

    timeDifference() {
      const CurrentTime = moment(new Date());
      const givenTime = moment(this.dateTimeValue);
      return givenTime?.diff(CurrentTime, "minutes");
    },

    dataValidation() {
      if (this.timeDifference() <= 0) {
        this.showNotification(
          "You can’t select past time.Select a future time.",
          // "You can’t select a past date.Select present or a future date.",
          "warning"
        );
        return false;
      }

      if (this.timeDifference() < 30) {
        this.showNotification(
          "Select a time at least 30 minutes later from now.",
          "warning"
        );
        return false;
      }
      if (this.timeZoneValue?.id == 999 && this.timeDifference() < 1440) {
        this.showNotification(
          "Select a time at least 24 hours later from now.",
          "warning"
        );
        return false;
      }
      return true;
    },
    onResumeHandler() {
      this.dateTimeValue = this.combineDateTime(
        this.scheduleDate,
        this.scheduleTime
      );
      if (this.dataValidation()) {
        const payload = {
          ...this.prePayload,
          timeZoneId: this.timeZoneValue?.id,
          timezone: this.timeZoneLocal,
          dateOfSchedule: this.dateTimeValue,
          timeOfSchedule: this.dateTimeValue,
          utcTimeOfSend: changeTimeZone(
            this.dateTimeValue,
            this.timeZoneValue.id != 999
              ? this.timeZoneValue?.name
              : this.timeZoneClubValue?.name
          ),
          campaignStatus: 3,
        };
        this.onConfirm(payload);
      }
    },
  },
};
</script>

<style></style>
