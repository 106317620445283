<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>

<AppLoader v-if="isLoading"></AppLoader>
<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />

  <div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div
        class="d-flex flex-wrap justify-content-between align-items-center py-9 px-20 px-15-sm border-bottom-normal-1"
      >
        <div class="w-100-vsm">
          <h2 class="font-16 font-w-500 mb-0">Campaigns</h2>
          <p class="font-12 color-light2 my-2 my-sm-0">
            Displaying a list of all campaigns you have created.
          </p>
        </div>
        <div class="w-100-vsm">
          <kbutton
            :class="'d-flex align-items-center'"
            v-if="!ifUserIsReportViewer"
            :theme-color="'primary'"
            :disabled="this.hasCreateAccess() === false"
            @click="navigateToCreatePage"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 7.5V3.5H7V7.5H3V9.5H7V13.5H9V9.5H13V7.5H9Z"
                fill="white"
              />
            </svg>
            Create Campaign
          </kbutton>
        </div>
      </div>

      <div
        v-if="campaignsList?.items?.length > 0 || searchText || campaignValue?.id !=0 || campaignTypeValue?.id !=0 || filterByDateValue?.id!=0 "
        class="d-flex flex-wrap justify-content-between px-20 px-15-sm pt-20 pb-0"
      >
        <div class="d-flex flex-wrap align-items-start gap-2">
          <div class="tab-with-button-wrap d-flex mb-9">
            <kbutton
              :class="{ active: !isArchived }"
              @click="toggleCampaign(false)"
              >All Campaign</kbutton
            >
            <kbutton
              :class="{ active: isArchived }"
              @click="toggleCampaign(true)"
              >Archived Campaign</kbutton
            >
          </div>
          <div
            class="search-box-wrap width-220 w-100-vsm mb-9 me-0 me-sm-3 me-md-4"
          >
            <span class="k-icon k-i-zoom"></span>
            <k-input
              :class="'mb-0'"
              placeholder="Search"
              v-model="searchQuery"
              @input="debounceSearch"
            >
            </k-input>
          </div>
        </div>

        <div class="d-flex flex-wrap gap-3">
          <div class="w-100-vsm mb-9">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                >Filter by Status</label
              >
              <dropdownlist
                :class="'w-100-vsm'"
                :style="{ width: '140px' }"
                :data-items="campaignStatusTemplates"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="campaignValue"
                @change="handleCampaignStatusChange"
              >
              </dropdownlist>
            </span>
          </div>

          <div class="w-100-vsm">
            <span class="d-flex flex-wrap align-items-center w-100-vsm">
              <label class="font-13 font-w-500 mb-0 pe-2 w-100-vsm mb-4-vsm"
                >Filter by Date</label
              >
              <dropdownlist
                :class="'w-100-vsm'"
                :style="{ width: '140px' }"
                :data-items="filterByDateType"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="filterByDateValue"
                @change="handleFilterByDateChange"
              >
              </dropdownlist>
            </span>
          </div>
          <div class="w-100-vsm" v-if="filterByDateValue?.id===4">
            <AppDateRangePicker
              @dateSelected="handleDateSelected"
              @error="handleError"
              :class="'w-100-vsm'"
              :style="{ width: '210px' }"
            >
            </AppDateRangePicker>
          </div>
        </div>
      </div>
    </div>

    <!--dashboard-content-->
    <div class="dashboard-content py-0 px-20 px-15-sm">
      <grid
        v-if="campaignsList?.items?.length > 0"
        :class="'campaigns-table table-design-2 mb-60 2xl:h-[48.813rem] lg:h-[37.75rem]'"
        :data-items="campaignsList?.items"
        :take="take"
        :skip="skip"
        :total="total"
        :pageable="gridPageable"
        @datastatechange="dataStateChange"
        :sortable="true"
        :sort="sort"
        @sortchange="sortChangeHandler"
        :columns="allCmpaignColumns"
      >
        <template v-slot:nameCell="{ props }">
          <td>
            <span
              :disabled="this.hasEditAccess() == false"
              @click="doNavigateToViewCampaign(props.dataItem)"
              class="font-14 font-w-500 color-blue cursor-pointer"
              >{{cardNameFormat(props.dataItem.campaignName)}}</span
            >
            <div
              class="campaign-transaction d-flex flex-wrap justify-content-start align-items-center"
            >
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot purple radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Sent: {{props.dataItem?.uniqueSent}}
                </div>
              </div>
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot green radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Delivered: {{props.dataItem?.uniqueDelivered}}
                </div>
              </div>
              <div
                class="campaign-transaction-box d-flex justify-content-start align-items-center"
              >
                <div class="dot red radius-100 ms-0 me-1"></div>
                <div class="text font-12 font-w-500">
                  Bounced: {{props.dataItem?.uniqueBounced}}
                </div>
              </div>
            </div>
          </td>
        </template>

        <template v-slot:typesOfDeliveryCell="{ props }">
          <td>
            <div class="d-flex align-items-center gap-2">
              <span v-if="props.dataItem.distributionChannel !== Email">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6673 1.99984C14.6673 1.2665 14.0673 0.666504 13.334 0.666504H2.66732C1.93398 0.666504 1.33398 1.2665 1.33398 1.99984M14.6673 1.99984V9.99984C14.6673 10.7332 14.0673 11.3332 13.334 11.3332H2.66732C1.93398 11.3332 1.33398 10.7332 1.33398 9.99984V1.99984M14.6673 1.99984L8.00065 6.6665L1.33398 1.99984"
                    stroke="#ADB2BD"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span v-if="props.dataItem.distributionChannel == Email">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 9C13 9.35362 12.8595 9.69276 12.6095 9.94281C12.3594 10.1929 12.0203 10.3333 11.6667 10.3333H3.66667L1 13V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H11.6667C12.0203 1 12.3594 1.14048 12.6095 1.39052C12.8595 1.64057 13 1.97971 13 2.33333V9Z"
                    stroke="#ADB2BD"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="font-12 font-w-500"
                >{{props?.dataItem?.distributionChannel == 1 ?
                "Email":"SMS"}}</span
              >
            </div>
          </td>
        </template>

        <template v-slot:statusCell="{ props }">
          <td>
            <span
              :class="['campaign-status styled-2 font-12 font-w-600 radius-100 py-1 px-8 text-center',campaignStatusColor[props?.dataItem?.campaignStatus]]"
            >
              {{campaignStatus[props?.dataItem?.campaignStatus]}}
            </span>
          </td>
        </template>

        <template v-slot:createdAtCell="{ props }">
          <td>
            <p class="font-14 text-color m-0">
              {{ getDate(props.dataItem?.createdAt)}}
            </p>
            <p class="font-12 text-color-2 m-0">
              {{ getTime(props.dataItem?.createdAt)}}
            </p>
          </td>
        </template>
        <template v-slot:LastUpdateAtCell="{ props }">
          <td>
            <p class="font-14 text-color m-0">
              {{ getDate(props.dataItem?.updatedAt)}}
            </p>
            <p class="font-12 text-color-2 m-0">
              {{ getTime(props.dataItem?.updatedAt)}}
            </p>
          </td>
        </template>

        <template v-slot:openRateCell="{ props }">
          <td>
            <p
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.uniqueOpened,props.dataItem?.uniqueSent) ? '' : 'null']"
            >
              {{percentageCalc(props.dataItem?.uniqueOpened,props.dataItem?.uniqueDelivered)||
              0}}%
            </p>
            <p class="font-12 m-0">
              {{props.dataItem?.uniqueDelivered||0}} Recipients
            </p>
          </td>
        </template>
        <template v-slot:bounceRateCell="{ props }">
          <td>
            <p
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.totalBounced,props.dataItem?.totalSent) ? '' : 'null']"
            >
              {{percentageCalc(props.dataItem?.totalBounced,props.dataItem?.totalSent)||
              0}}%
            </p>
            <p class="font-12 m-0">
              {{props.dataItem?.uniqueSent||0}} Recipients
            </p>
          </td>
        </template>
        <template v-slot:conversionRateCell="{ props }">
          <td>
            <h3
              :class="['campaign-stat-number font-14 m-0', percentageCalc(props.dataItem?.totalConversion,props.dataItem?.totalDelivered) ? '' : 'null']"
            >
              {{percentageCalc(props.dataItem?.totalConversion,props.dataItem?.uniqueDelivered)||
              0}}%
            </h3>
            <p class="font-12 m-0">
              ${{parseFloat((props.dataItem?.totalConversionValue ||
              0).toFixed(2))}} Conv. value
            </p>
          </td>
        </template>
        <template v-slot:actionCell="{ props }">
          <td>
            <AppPopUp
              :voucher="props.dataItem"
              :items="generateCampaignActions(props.dataItem?.campaignStatus,props?.dataItem)"
              @dropId="getDropId"
              :icon="'more-vertical'"
              :popUpSettings="popupSettings"
              :buttonClass="'btn-clear'"
            >
            </AppPopUp>
          </td>
        </template>
      </grid>

      <div
        v-if="!isLoading && campaignsList?.items?.length === 0 "
        class="d-flex flex-column justify-content-center align-items-center mt-5 pt-5"
      >
        <svg
          width="217"
          height="184"
          viewBox="0 0 217 184"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.05" cx="109" cy="92" r="92" fill="#253858" />
          <g filter="url(#filter0_bdd_5224_78028)">
            <rect
              x="31.9402"
              y="54.8408"
              width="154.563"
              height="98.2819"
              rx="1.93432"
              fill="#DEDEDE"
              fill-opacity="0.13"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter1_bdd_5224_78028)">
            <rect
              x="28.2571"
              y="45.7461"
              width="161.928"
              height="102.965"
              rx="1.88497"
              fill="#DEDEDE"
              fill-opacity="0.13"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter2_dd_5224_78028)">
            <rect
              x="23"
              y="34.8228"
              width="170.842"
              height="108.97"
              rx="3.69388"
              fill="white"
            />
          </g>
          <path
            d="M63.0001 68.5L107.167 28.216C107.924 27.5251 109.082 27.5189 109.846 28.2015L155 68.5V117C155 118.105 154.105 119 153 119H65C63.8954 119 63 118.105 63 117L63.0001 68.5Z"
            fill="#404A61"
          />
          <g filter="url(#filter3_d_5224_78028)">
            <path
              d="M71 48V81V112C71 113.105 71.8954 114 73 114H143.5C144.605 114 145.5 113.105 145.5 112V48C145.5 46.8954 144.605 46 143.5 46H73C71.8954 46 71 46.8954 71 48Z"
              fill="white"
            />
            <path
              d="M71 48V81V112C71 113.105 71.8954 114 73 114H143.5C144.605 114 145.5 113.105 145.5 112V48C145.5 46.8954 144.605 46 143.5 46H73C71.8954 46 71 46.8954 71 48Z"
              stroke="#D2E1F9"
            />
          </g>
          <path d="M155 68.5V131L116 101L155 68.5Z" fill="#A4C3F3" />
          <path d="M63 68.5V131L102.5 101.5L63 68.5Z" fill="#A4C3F3" />
          <path
            d="M155 131H63L103.5 100.5L109.5 106.5L115.5 100.5L155 131Z"
            fill="#88B0ED"
          />
          <rect
            opacity="0.5"
            x="78.5"
            y="67"
            width="58"
            height="5"
            rx="2.5"
            fill="#A4C3F3"
          />
          <rect
            opacity="0.5"
            x="78.5"
            y="76"
            width="50"
            height="5"
            rx="2.5"
            fill="#A4C3F3"
          />
          <rect x="78.5" y="53" width="45" height="7" rx="3.5" fill="#A4C3F3" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M133.5 51C130.739 51 128.5 53.2386 128.5 56C128.5 58.7614 130.739 61 133.5 61C136.261 61 138.5 58.7614 138.5 56C138.5 53.2386 136.261 51 133.5 51ZM135.667 55.1545C135.707 55.1089 135.738 55.0558 135.757 54.9983C135.776 54.9407 135.783 54.88 135.778 54.8195C135.774 54.7591 135.757 54.7003 135.729 54.6464C135.701 54.5926 135.663 54.5449 135.616 54.5061C135.57 54.4673 135.516 54.4382 135.458 54.4205C135.4 54.4028 135.339 54.3969 135.279 54.4031C135.218 54.4093 135.16 54.4275 135.107 54.4567C135.054 54.4858 135.007 54.5252 134.969 54.5727L133.015 56.9177L132.003 55.9059C131.917 55.8231 131.803 55.7773 131.683 55.7783C131.564 55.7794 131.45 55.8272 131.366 55.9114C131.282 55.9957 131.234 56.1097 131.233 56.2289C131.232 56.3481 131.278 56.4629 131.36 56.5486L132.724 57.9123C132.769 57.9569 132.822 57.9917 132.881 58.0146C132.94 58.0374 133.003 58.0477 133.066 58.0448C133.129 58.042 133.191 58.026 133.247 57.9979C133.304 57.9699 133.354 57.9303 133.395 57.8818L135.667 55.1545Z"
            fill="#51D4F0"
          />
          <defs>
            <filter
              id="filter0_bdd_5224_78028"
              x="23.901"
              y="46.8017"
              width="170.641"
              height="114.36"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feflood flood-opacity="0" result="BackgroundImageFix" />
              <fegaussianblur in="BackgroundImage" stdDeviation="4.01957" />
              <fecomposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_5224_78028"
              />
              <fecolormatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feoffset dy="1.81524" />
              <fegaussianblur stdDeviation="1.81524" />
              <fecomposite in2="hardAlpha" operator="out" />
              <fecolormatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              />
              <feblend
                mode="normal"
                in2="effect1_backgroundBlur_5224_78028"
                result="effect2_dropShadow_5224_78028"
              />
              <fecolormatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feoffset dy="4.01957" />
              <fegaussianblur stdDeviation="2.00978" />
              <fecomposite in2="hardAlpha" operator="out" />
              <fecolormatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feblend
                mode="normal"
                in2="effect2_dropShadow_5224_78028"
                result="effect3_dropShadow_5224_78028"
              />
              <feblend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_5224_78028"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_bdd_5224_78028"
              x="20.4622"
              y="37.9512"
              width="177.518"
              height="118.555"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feflood flood-opacity="0" result="BackgroundImageFix" />
              <fegaussianblur in="BackgroundImage" stdDeviation="3.89744" />
              <fecomposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_5224_78028"
              />
              <fecolormatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feoffset dy="1.76009" />
              <fegaussianblur stdDeviation="1.76009" />
              <fecomposite in2="hardAlpha" operator="out" />
              <fecolormatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
              />
              <feblend
                mode="normal"
                in2="effect1_backgroundBlur_5224_78028"
                result="effect2_dropShadow_5224_78028"
              />
              <fecolormatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feoffset dy="3.89744" />
              <fegaussianblur stdDeviation="1.94872" />
              <fecomposite in2="hardAlpha" operator="out" />
              <fecolormatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feblend
                mode="normal"
                in2="effect2_dropShadow_5224_78028"
                result="effect3_dropShadow_5224_78028"
              />
              <feblend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_5224_78028"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_dd_5224_78028"
              x="0.8367"
              y="20.0472"
              width="215.169"
              height="153.296"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feflood flood-opacity="0" result="BackgroundImageFix" />
              <fecolormatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feoffset dy="7.38777" />
              <fegaussianblur stdDeviation="11.0816" />
              <fecomposite in2="hardAlpha" operator="out" />
              <fecolormatrix
                type="matrix"
                values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"
              />
              <feblend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5224_78028"
              />
              <fecolormatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feoffset dy="1.84694" />
              <fegaussianblur stdDeviation="1.84694" />
              <fecomposite in2="hardAlpha" operator="out" />
              <fecolormatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feblend
                mode="normal"
                in2="effect1_dropShadow_5224_78028"
                result="effect2_dropShadow_5224_78028"
              />
              <feblend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_5224_78028"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_5224_78028"
              x="66.8061"
              y="39.6561"
              width="82.8878"
              height="76.3878"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feflood flood-opacity="0" result="BackgroundImageFix" />
              <fecolormatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feoffset dy="-2.15" />
              <fegaussianblur stdDeviation="1.84694" />
              <fecomposite in2="hardAlpha" operator="out" />
              <fecolormatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feblend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5224_78028"
              />
              <feblend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_5224_78028"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
        <h5
          class="font-19 text-color mt-32 mb-1"
        >
          Campaign
      </h5>
        <p
          v-if="!searchQuery && !(campaignValue?.id !=0 && campaignsList?.items?.length == 0) && isArchived"
          class="font-14 color-light2 mt-4 mb-4 text-center"
        >
          Archive is currently empty. When you archive a campaign, <br> it will
          appear here.
        </p>
        <!-- <h5
          v-if="!searchQuery && !(campaignValue?.id !=0 && campaignsList?.items?.length == 0) && !isArchived"
          class="font-19 text-color mt-32 mb-1"
        >
          Campaign
      </h5> -->
        <p
          v-if="!searchQuery && !((campaignValue?.id !=0||filterByDateValue?.id!=0) && campaignsList?.items?.length == 0)&& !isArchived"
          class="font-14 color-light2 mt-4 mb-4 text-center"
        >
          Create engaging campaigns or newsletters to keep <br />
          your club members stay up to date.
        </p>
        <p
          v-if="searchQuery || ((campaignValue?.id !=0||filterByDateValue?.id!=0) && campaignsList?.items?.length == 0 ) && !isArchived"
          class="font-14 color-light2 mb-4 text-center"
        >
          No campaigns found matching your search/filter criteria.
        </p>

        <kbutton
          v-if="!searchQuery && !((campaignValue?.id !=0||filterByDateValue?.id!=0) && campaignsList?.items?.length == 0 ) && !isArchived"
          :disabled="!hasCreateAccess()"
          :class="'mb-3'"
          :theme-color="'primary'"
          @click="navigateToCreatePage"
        >
          Create Campaign
        </kbutton>
      </div>
    </div>
  </div>
</div>

<WarningModal
  v-if="deleteCampaignModalVisibility"
  :modalDetails="deleteCampaignModalDetails"
  :onCancel="onCancelDeleteCampaignModal"
  :onConfirm="onConfirmDeleteCampaignModal"
/>

<WarningModal
  v-if="pauseCamaignWarningModalVisibility"
  :modalDetails="pauseCampaignWarningModalDetails"
  :onCancel="onCancelPauseCampaign"
  :onConfirm="onConfirmPauseCampaign"
/>

<WarningModal
  v-if="resumeCampaignWarningModalVisibility"
  :modalDetails="resumeCampaignWarningModalDetails"
  :onCancel="onCancelResumeCampaign"
  :onConfirm="onConfirmResumeCampaign"
/>

<RescheduleCampaignModal
  :prePayload="selectedCampaign"
  :onCancel="onCancelRescheduleCampaign"
  :onConfirm="onConfirmRescheduleCampaign"
  v-if="RescheduleCampaignModalVisibility"
/>
